html {
    height: 100%;
    overflow-y: hidden;
}

body {
    margin: 0px;
    height: 100%;
}

#root {
    height: 100%;
}